<template>
  <div>
    <div class="case">
      <div class="indexTitle">合作案例</div>
      <div class="type-area">
        <span
          v-for="(item, index) in changeType"
          :key="index"
          class="type"
          :class="activeIndex == index ? 'activeType' : ''"
          @click="changeIndex(index, item)"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="case_box">
      <div
        class="content"
        v-loading="loading"
        element-loading-text="拼命加载中"
        style="min-height: 217px"
      >
        <div class="type-area">
          <ul class="item-list">
            <li
              class="item"
              v-for="(item, index) in listCase"
              @click="toDetails(item)"
            >
              <div class="img-div">
                <img :src="item.img" />
              </div>
              <!--<div class="title">-->
              <!--<span v-for="(com, ind) in item.computedDigest">-->
              <!--<span class="name">{{com}}</span>-->
              <!--<span class="cat-line" v-if="(item.computedDigest.length-1) != ind"></span>-->
              <!--</span>-->
              <!--</div>-->
            </li>
          </ul>
          <div class="page" v-if="total > 9">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              prev-text="上一页"
              next-text="下一页"
              :page-size="9"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 76px; background: #f0f2f5"></div>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

export default {
  name: "CooperationCase", //合作案例
  data() {
    return {
      changeType: [],
      activeIndex: 0,
      tagName: "", //标签名
      listCase: [],
      total: 0,
      loading: true,
      // computedDigest:[]
    };
  },
  mounted() {
    this.getCaseTag();
  },

  methods: {
    //获取标签
    getCaseTag() {
      caseTag().then((res) => {
        if (res.code == 200) {
          this.changeType = res.result;
          this.changeType.unshift("全部");
          this.tagName = res.result[0]; //设置默认选中的标签
          this.getCaseGet({
            pageIndex: 1,
            pageSize: 9,
            tagName: "",
            typeCode: "hezuoanli",
          });
        }
      });
    },
    //  获取标签下列表
    getCaseGet(parms) {
      this.loading = false;
      caseGet(parms).then((res) => {
        // console.log(res);
        this.total = res.result.total;
        this.listCase = res.result.records;
        this.listCase.map((item) => {
          item.computedDigest = item.digest.split("|"); // 通过竖线剪切
        });
      });
    },
    changeIndex(index, value) {
      this.activeIndex = index;
      this.tagName = value;
      // this.tagName = '';
      if (value != "全部") {
        // 不是第一位携带tagName
        this.getCaseGet({
          pageIndex: 1,
          pageSize: 9,
          tagName: this.tagName,
          typeCode: "hezuoanli",
        });
        return false;
      }
      this.getCaseGet({
        pageIndex: 1,
        pageSize: 9,
        tagName: "",
        typeCode: "hezuoanli",
      }); //第一个时tagName为空
    },
    //  当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // console.log(this.tagName);
      if (this.tagName != "全部") {
        this.getCaseGet({
          pageIndex: val,
          pageSize: 9,
          tagName: this.tagName,
          typeCode: "hezuoanli",
        });
      } else {
        this.getCaseGet({
          pageIndex: val,
          pageSize: 9,
          tagName: "",
          typeCode: "hezuoanli",
        });
      }
    },
    //  详情页
    toDetails(info) {
      if (info.digest == "已上线") {
        if (this.tagName != "全部") {
          this.$router.push({
            path: "/case/detail",
            // query: { id: info.id, tagName: this.tagName, title: info.title }
            query: { id: info.id },
          });
        } else {
          // this.$router.push({ path: "/case/detail", query: { id: info.id, tagName: "", title: info.title } });
          this.$router.push({ path: "/case/detail", query: { id: info.id } });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.case {
  background: #fff;
  height: 230px;
  overflow: hidden;
}
.type-area {
  width: 1180px;
  margin: 0 auto;
}
.case_box {
  background: #f1f2f6;
}
.indexTitle {
  margin: 57px auto 67px auto;
}

.type {
  height: 36px;
  box-sizing: border-box;
  text-align: center;
  padding: 11px 31px;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  border: 1px solid #f1f2f6;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 14px;
  background: #f1f2f6;
  color: #797c80;
  padding-top: 12px;
}

.type:hover {
  border: 1px solid #0f80dc;
  color: #0f80dc;
  cursor: pointer;
  background: #fff;
}
.activeType {
  background: #0f80dc;
  color: #fff;
  border: 1px solid #0f80dc !important;
}

.content {
  // background: #fff;
  padding-top: 40px;
  .item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
      /* 解决最后一排不足三个不能对齐 */
      content: "";
      width: 384px;
    }
    .item {
      background: #fff;
      width: 384px;
      height: 217px;
      margin-bottom: 14px;
      &:hover {
        box-shadow: 0px 10px 18px 0px rgba(141, 149, 159, 0.23);
        cursor: pointer;
      }
      .img-div {
        width: 100%;
        height: 217px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        margin-top: 16px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        /*text-align: left;*/
        color: #5c6066;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        & > span {
          flex-shrink: 0;
        }
        .name {
          display: inline-block;
          padding: 0 12px;
        }
        .cat-line {
          display: inline-block;
          width: 1px;
          height: 9px;
          background: #d9dbdd;
        }
      }
    }
  }

  .page {
    padding: 36px 0 100px 0;
  }
}
</style>
